import Vue from 'vue'
import moment from 'moment-timezone'

/**
 * Fixed to certain decimal points
 */
Vue.filter('toFixed', function(price, limit) {
  return price.toFixed(limit)
})

/**
 * Get Time Ago Date
 */
Vue.filter('timeAgo', function(date) {
  return moment(date).fromNow()
})

/**
 * Format Date
 */
Vue.filter('formatDate', function(date, format) {
  return moment(date).format(format)
})

/**
 * Format Time
 */
Vue.filter('formatTime', function(time, format) {
  return moment(time, 'HH:mm').format(format)
})

/**
 * Is Today's Date
 */
Vue.filter('sessionGRPDate', function(date, format) {
  if (moment(date).isSame(moment().format('YYYY-MM-DD'))) {
    return 'Today'
  } else if (
    moment(date).isSame(
      moment()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    )
  ) {
    return 'Tomorrow'
  } else {
    return moment(date).format(format)
  }
})
/**
 * sub string
 */
Vue.filter('subStr', function(string, length) {
  if (length) {
    return string.substring(0, length) + '...'
  }
  return string.substring(0, 15) + '...'
})
