import gql from 'graphql-tag'

export const REGISTER = gql`
  mutation UserResgisterMutation($registerData: RegisterInput!) {
    registerUser(registerData: $registerData) {
      success
      user {
        id
        username
        firstName
        lastName
        email
        verifiedEmail
        phoneNumber
      }
      error
      accesstoken
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation ChangePasswordMutation($changeInput: PasswordChangeInput!) {
    changePassword(changeInput: $changeInput) {
      success
      accesstoken
      error
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUserMutation($userData: UpdateUserInput!) {
    updateUser(userData: $userData) {
      error
      success
    }
  }
`

export const AVAILABLE = gql`
  query UserPresentOutput($emailInput: UserEmailInput!) {
    isUserRegistered(emailInput: $emailInput) {
      present
    }
  }
`
export const CREATE_TALENT = gql`
  mutation TalentResgisterMutation($talentData: TalentInput!) {
    registerTalent(talentData: $talentData) {
      success
      talent {
        id
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
          zipcode
          city
          phoneCode
          didFinishRegistration
        }
      }
      accesstoken
      error
    }
  }
`
export const UPDATE_TALENT_INTRO = gql`
  mutation UpdateTalentMutation($talentData: UpdateTalentInput!) {
    updateTalent(talentData: $talentData) {
      success
      talent {
        id
        user {
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
          zipcode
          city
          country {
            id
            code
            name
            enabled
          }
          timezone {
            id
            name
          }
          language {
            id
            name
            localName
          }
        }
      }
      error
    }
  }
`

export const UPDATE_PROFILE_IMG = gql`
  mutation UpdateProfileImageMutation(
    $imageFile: Upload!
    $tokenData: TokenAuthInput!
  ) {
    updateProfileImage(imageFile: $imageFile, tokenData: $tokenData) {
      success
      url
      error
    }
  }
`

export const UPDATE_PROFILE_VIDEO = gql`
  mutation UploadVideoMutation(
    $videoFile: Upload!
    $tokenData: TokenAuthInput!
  ) {
    updateProfileVideo(videoFile: $videoFile, tokenData: $tokenData) {
      success
      url
      error
    }
  }
`

export const UPDATE_TALENT_GALLERY = gql`
  mutation UploadTalentGalleryMutation($galleryInput: GalleryInput!) {
    uploadTalentGallery(galleryInput: $galleryInput) {
      success
      gallery {
        id
        image
      }
      error
    }
  }
`
export const REMOVE_TALENT_AREA = gql`
  mutation RemoveTalentAreaMutation($talentareaData: SetTalentAreaInput!) {
    removeTalentArea(talentareaData: $talentareaData) {
      success
      talent {
        id
        user {
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
        }
        shortIntro
        longIntro
        verified
        featured
        followersCount
        introVideo
        areas {
          id
          name
          order
        }
      }
      error
    }
  }
`

export const SET_TALENT_AREA = gql`
  mutation SetTalentAreaMutation($talentareaData: SetTalentAreaInput!) {
    setTalentArea(talentareaData: $talentareaData) {
      success
      talent {
        id
        user {
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
        }
        shortIntro
        longIntro
        verified
        featured
        followersCount
        introVideo
        areas {
          id
          name
          order
        }
      }
      error
    }
  }
`

export const GET_CARD_DETAILS = gql`
  query DisplayCardListOutput($tokenInput: TokenInput!) {
    fetchUserCards(tokenInput: $tokenInput) {
      success
      cardList {
        id
        accountid
        cardid
        last4
        brand
        expYear
        expMonth
      }
      totalCards
      error
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query DisplayNotificationListType(
    $tokenInput: TokenInput!
    $pagination: PaginationInput
    $tzinfo: String
  ) {
    getNotifications(
      tokenInput: $tokenInput
      pagination: $pagination
      tzinfo: $tzinfo
    ) {
      success
      error
      first
      skip
      totalNotifications
      unreadNotifications
      notifications {
        id
        action
        createdOn
        bookedSession {
          id
          sessionType {
            id
            title
            length
            charge
          }
          date
          startTime
          endTime
        }
        read
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
        host {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
      }
    }
  }
`
export const MARK_NOTIFICATION_READ = gql`
  mutation ReadNotification($notificationData: NotificationInput!) {
    markNotificationRead(notificationData: $notificationData) {
      success
      error
      unreadNotifications
    }
  }
`
export const DISMISS_NOTIFICATION = gql`
  mutation DismissNotification(
    $notificationData: NotificationIdInput!
    $tzinfo: String
  ) {
    dismissNotification(notificationData: $notificationData, tzinfo: $tzinfo) {
      success
      error
      first
      skip
      totalNotifications
      unreadNotifications
      notifications {
        id
        action
        createdOn
        bookedSession {
          id
          sessionType {
            id
            title
            length
            charge
          }
          date
          startTime
          endTime
        }
        read
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
        host {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
      }
    }
  }
`

export const GET_ADDRESS = gql`
  query addressByContactOrTenant($input: ContactOrTenantInput) {
    addressByContactOrTenant(input: $input) {
      id
      street
      street2
      city
      country
      province
      postalCode
    }
  }
`

export const GET_LOCALE = gql`
  query {
    allLegalLocales {
      nodes {
        id
        code
        name
        enabled
      }
    }
    allTimezones {
      nodes {
        id
        name
      }
    }
    allLanguages {
      nodes {
        id
        name
        localName
      }
    }
  }
`

export const GET_PHONE = gql`
  query phoneNumberByContact($input: ContactOrTenantInput) {
    phoneNumberByContact(input: $input) {
      id
      number
    }
  }
`

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($input: AddressInput) {
    updateAddress(input: $input) {
      id
      name
      street
      street2
      city
      province
      postalCode
      country
    }
  }
`

export const UPDATE_MEMBER = gql`
  mutation updateMemberSubset($input: MemberInfoInputSubset) {
    updateMemberSubset(input: $input) {
      id
      firstName
      lastName
      birthdate
      legalLocaleId
      timezoneId
      displayName
    }
  }
`

export const UPDATE_PHONE = gql`
  mutation updatePhoneNumber($input: ContactPhoneInput!) {
    updatePhoneNumber(input: $input) {
      number
    }
  }
`

export const UPDATE_EMAIL = gql`
  mutation contactsUpsertEmail($input: EmailInput) {
    contactsUpsertEmail(input: $input) {
      email
    }
  }
`

export const UPDATE_USERNAME = gql`
  mutation iamUpsertUsername($input: IamUpsertUsernameUpsert) {
    iamUpsertUsername(input: $input)
  }
`

export const UPSERT_STRIPE_INTEGRATION = gql`
  mutation addStripeConnectIntegration($input: StripeCodeInput!) {
    addStripeConnectIntegration(input: $input)
  }
`

export const GET_STRIPE_CONNECT_LINK = gql`
  query stripeConnectLinkByMember($memberId: Int!) {
    stripeConnectLinkByMember(memberId: $memberId) {
      created
      url
    }
  }
`
export const SLUG = gql`
  mutation setMemberSlug($input: SlugInput!) {
    addMemberSlug(input: $input) {
      slug
      priority
    }
  }
`

export const CHECK_SLUG = gql`
  query checkSlug($input: SlugInput!) {
    checkSlug(input: $input) {
      id
      slug
      priority
    }
  }
`

export const getMemberSlug = gql`
  query getMemberSlug($id: Int, $priority: Int) {
    member(id: $id) {
      id
      slugs(priority: $priority) {
        priority
        slug
      }
    }
  }
`

export const GET_MEMBER_CONTACT_METHOD = gql`
  query getMemberContactMethod($contactId: Int) {
    getMemberContactMethod(contactId: $contactId) {
      contactId
      typeId
      priority
      phoneId
      emailId
      addressId
    }
  }
`

export const SET_CONTACT_METHOD = gql`
  mutation setContactMethod($input: MemberContactMethodInput) {
    setContactMethod(input: $input) {
      id
    }
  }
`

export const UPLOAD_BASE64_FILE = gql`
  mutation UploadBase64Mutation($baseData: Base64Input!) {
    uploadBase64File(baseData: $baseData) {
      success
      url
      error
    }
  }
`
export const SWAP_PRIMARY_SECONDARY_PHOTO = gql`
  mutation SwapPrimarySecondaryPhotoMutation(
    $swapPhotoData: SwapTalentPhotoInput!
  ) {
    swapPrimarySecondaryPhoto(swapPhotoData: $swapPhotoData) {
      success
      error
      gallery {
        id
        image
      }
      talent {
        profileImg
      }
    }
  }
`
export const DELETE_TALENT_GALLERY = gql`
  mutation DeleteTalentGalleryMutation($galleryInput: DeleteGalleryInput!) {
    deleteTalentGallery(galleryInput: $galleryInput) {
      success
      gallery {
        id
        image
      }
      error
    }
  }
`

export const UPDATE_USER_NAME = gql`
  mutation UpdateUsernameMutation($userData: UpdateUsernameInput!) {
    updateUsername(userData: $userData) {
      success
      error
    }
  }
`

export const UPDATE_USER_FINISH = gql`
  mutation UpdateUserFinishMutation($userData: UpdateUserfinishInput!) {
    updateUserFinish(userData: $userData) {
      success
      istalent
      error
    }
  }
`

export const CONNECT_GOOGLE_CALENDER = gql`
  mutation GoogleCalendarMutation($googleData: GoogleToken!) {
    connectGoogleCalendar(googleData: $googleData) {
      success
      error
    }
  }
`

export const DISCONNECT_GOOGLE_CALENDER = gql`
  mutation DisconnectGoogleCalendarMutation($userData: TokenInput!) {
    disconnectGoogleCalendar(userData: $userData) {
      success
      error
    }
  }
`

export const GET_STRIPE_ACCOUNT = gql`
  mutation GetStripeAccountMutation($stripeData: StripeInput!) {
    getStripeAccountUrl(stripeData: $stripeData) {
      success
      error
      url
    }
  }
`
export const CREATE_STRIPE_ACCOUNT = gql`
  mutation CreateStripeAccountMutation($stripeData: StripeInput!) {
    createStripeAccount(stripeData: $stripeData) {
      success
      error
      stripeId
    }
  }
`

export const AUTHORIZE_STRIPE_ACCOUNT = gql`
  mutation AuthorizeStripeAccountMutation(
    $stripeData: StripeAuthorizeConnectInput!
  ) {
    authorizeStripeAccount(stripeData: $stripeData) {
      success
      error
    }
  }
`
