import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    // User Routes
    {
      path: '/',
      name: 'Home',
      component: () => import('@/pages/Home.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/upcoming-sessions',
      name: 'UpcomingSessions',
      component: () => import('@/pages/user/UpcomingSessions.vue'),
      meta: {
        requiresAuth: true,
        user: true,
      },
    },
    {
      path: '/terms',
      name: 'TermsOfService',
      component: () => import('@/pages/legal/TermsOfService.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('@/pages/legal/PrivacyPolicy.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: () => import('@/pages/legal/Faq.vue'),
      meta: {
        guest: true,
      },
    },

    // Common Routes (USER + TALENT)
    {
      path: '/account/settings',
      name: 'AccountSettings',
      component: () => import('@/pages/account/Settings.vue'),
      meta: {
        requiresAuth: true,
        user: true,
        talent: true,
      },
    },
    {
      path: '/notifications',
      name: 'Notifications',
      component: () => import('@/pages/Notifications.vue'),
      meta: {
        requiresAuth: true,
        user: true,
        talent: true,
      },
    },
    {
      path: '/email-verification',
      name: 'EmailVerification',
      component: () => import('@/pages/account/EmailVerification.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/password-reset',
      name: 'PasswordReset',
      component: () => import('@/pages/account/PasswordReset.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/signup',
      name: 'AccountSetup',
      component: () => import('@/pages/auth/Signup.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/demo-link/:channel',
      name: 'DemoLink',
      component: () => import('@/pages/DemoLink.vue'),
      meta: {
        guest: true,
      },
    },

    // Talent Routes
    {
      path: '/talent',
      component: {
        // Inline declaration of a component that renders our <router-view>
        render: c => c('router-view'), // eslint-disable-line
      },
      children: [
        // Auth Routes
        {
          path: 'email-confirmation',
          name: 'EmailConfirmation',
          component: () => import('@/pages/talent/auth/EmailConfirmation.vue'),
          meta: {
            guest: true,
          },
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import('@/pages/talent/dashboard/EditProfile.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: () => import('@/pages/talent/auth/ForgotPassword.vue'),
          meta: {
            guest: true,
          },
        },

        // Normal Routes
        {
          path: 'following',
          name: 'Following',
          component: () => import('@/pages/talent/following/Following.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
        {
          path: 'profile/:slug',
          name: 'TalentProfile',
          component: () => import('@/pages/talent/profile/Profile.vue'),
          meta: {
            guest: true,
          },
        },

        // Privacy & Terms Routes
        {
          path: 'terms',
          name: 'TalentTermsOfService',
          component: () => import('@/pages/legal/TalentTerms.vue'),
          meta: {
            guest: true,
          },
        },

        // Dashboard Routes
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('@/pages/talent/dashboard/Dashboard.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
        {
          path: 'sessions',
          name: 'NewSession',
          component: () => import('@/pages/talent/dashboard/NewSession.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
        {
          path: 'availability',
          name: 'Availability',
          component: () => import('@/pages/talent/dashboard/Availability.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
        {
          path: 'appointments',
          name: 'Appointments',
          component: () => import('@/pages/talent/dashboard/Appointments.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
        {
          path: 'help',
          name: 'Help',
          component: () => import('@/pages/talent/dashboard/Help.vue'),
          meta: {
            requiresAuth: true,
            talent: true,
          },
        },
      ],
    },

    // Admin Routes
    {
      path: '/admin',
      component: {
        // Inline declaration of a component that renders our <router-view>
        render: c => c('router-view'), // eslint-disable-line
      },
      children: [
        {
          path: 'dashboard',
          name: 'AdminDashboard',
          component: () => import('@/pages/admin/dashboard/Dashboard.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
          },
        },
        {
          path: 'sessions',
          name: 'AdminSessions',
          component: () => import('@/pages/admin/dashboard/Sessions.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
          },
        },
        {
          path: 'hosts',
          name: 'AdminHosts',
          component: () => import('@/pages/admin/dashboard/Hosts.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
          },
        },
        {
          path: 'users',
          name: 'AdminUsers',
          component: () => import('@/pages/admin/dashboard/Users.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
          },
        },
      ],
    },
    {
      path: '*',
      name: 'PageNotFound',
      component: () => import('@/pages/PageNotFound.vue'),
      meta: {
        guest: true,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest)) {
    next()
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      // If not loggedIn redirect to homepage
      next('/')
    } else {
      if (
        to.matched.some(record => record.meta.talent) &&
        store.getters.isTalent
      ) {
        next()
      } else if (
        to.matched.some(record => record.meta.user) &&
        !store.getters.isTalent
      ) {
        next()
      } else if (
        to.matched.some(record => record.meta.admin) &&
        store.getters.isAdmin
      ) {
        next()
      } else {
        next('/404')
      }
    }
  }
})

export default router
