<template>
  <div
    :class="{ 'fixed-bar': !isMobile, 'relative-bar': !$device.desktop }"
    app
    :style="isMobile ? { background: '#F1F3F6' } : appBarColor"
    data-aos="fade-down"
    data-aos-duration="900"
  >
    <v-container class="mobile-view">
      <v-row align="center" justify="center">
        <v-flex xs2 text-center>
          <div class="text-center mobile-menu">
            <v-menu
              v-if="!isLoggedIn"
              v-model="menu"
              :close-on-content-click="false"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  class="header-icon"
                  width="24px"
                  height="24px"
                  :src="require('@/assets/images/menu.png')"
                  alt="menu"
                  v-on="on"
                />
              </template>
              <v-card>
                <!-- <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        ><h4>
                          {{ $t('join_professional') }}
                        </h4></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
                <v-card-actions>
                  <v-btn color="secondary" left @click="loginModal()">
                    {{ $t('login_form_title') }}
                  </v-btn>
                  <v-btn color="secondary" left @click="signUpModal()">
                    {{ $t('sign_up') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-menu v-if="isLoggedIn" :close-on-content-click="false" offset-x>
              <template #activator="{ on, attrs }">
                <v-list class="py-0">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar class="rounded ma-0" size="48">
                      <v-img
                        v-bind="attrs"
                        class="elevation-6"
                        alt="ProfileImg"
                        :src="profileImage"
                        v-on="on"
                      />
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </template>
              <v-list>
                <v-list-item v-if="isAdmin" to="/admin/dashboard">
                  <v-list-item-title>Admin Dashboard</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!isTalent && !isAdmin"
                  to="/upcoming-sessions"
                >
                  <v-list-item-title>Upcoming Sessions</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!isAdmin" to="/account/settings">
                  <v-list-item-title>Account Settings</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isTalent && !isAdmin" to="/talent/dashboard">
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isTalent && !isAdmin" to="/talent/sessions">
                  <v-list-item-title>Sessions</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isTalent && !isAdmin"
                  to="/talent/availability"
                >
                  <v-list-item-title>Availability</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isTalent && !isAdmin"
                  to="/talent/appointments"
                >
                  <v-list-item-title>Appointments</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-flex>
        <v-flex xs8 text-center>
          <router-link to="/" class="black--text text-decoration-none">
            <img
              src="@/assets/images/logo/with-yellow.png"
              height="50%"
              width="30%"
            />
          </router-link>
        </v-flex>
        <v-flex xs2 text-center>
          <v-img
            class="header-icon"
            width="24px"
            height="24px"
            :src="require('@/assets/images/search.png')"
            alt="search"
            @click="isHidden = true"
          />
          <div v-if="isHidden" class="mobile-search">
            <v-row>
              <v-flex xs9>
                <v-autocomplete
                  v-model="talent"
                  class="auto-complete mobile-auto-complete"
                  :items="searchedTalents"
                  placeholder="Eg. Mick Gordon"
                  flat
                  tile
                  solo
                  hide-details
                  :hide-no-data="search && search.length ? false : true"
                  clearable
                  color="grey"
                  background-color="white"
                  prepend-inner-icon="mdi-magnify"
                  item-text="name"
                  item-value="slug"
                  :search-input.sync="search"
                  @input="toProfile"
                  @click:clear="clearSearch()"
                  @focus="clearSearch()"
                >
                  <template #item="data" class="mt-4">
                    <v-list-item-avatar tile size="30" rounded>
                      <v-img
                        :src="
                          data.item.profilePic
                            ? data.item.profilePic
                            : $defaultImg
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-1">
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-subtitle-2 light-text text-wrap"
                      >
                        {{ data.item.tagLine }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs3>
                <v-btn color="secondary" text @click="isHidden = !isHidden">
                  {{ $t('cancel') }}
                </v-btn>
              </v-flex>
            </v-row>
          </div>
        </v-flex>
      </v-row>
    </v-container>
    <v-row v-if="!isMobile" align="center" class="px-15 mt-3 desktop-view">
      <v-flex xs12 md4 text-md-left text-center>
        <router-link to="/" class="black--text text-decoration-none">
          <div class="logo-text d-inline">
            <img
              src="@/assets/images/logo/with-yellow.png"
              class="header-logo"
            />
          </div>
        </router-link>
      </v-flex>
      <v-flex xs12 md4 py-3 py-md-0>
        <v-card class="search-box">
          <v-autocomplete
            v-model="talent"
            class="auto-complete"
            :items="searchedTalents"
            placeholder="Eg. Mick Gordon"
            flat
            tile
            solo
            hide-details
            :hide-no-data="search && search.length ? false : true"
            clearable
            color="black"
            background-color="white"
            prepend-inner-icon="mdi-magnify"
            item-text="name"
            item-value="slug"
            :search-input.sync="search"
            @input="toProfile"
            @click:clear="clearSearch()"
            @focus="clearSearch()"
          >
            <template #item="data" class="mt-4">
              <v-list-item-avatar tile size="60" rounded>
                <v-img
                  :src="
                    data.item.profilePic ? data.item.profilePic : $defaultImg
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1">
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-subtitle-2 light-text text-wrap"
                >
                  {{ data.item.tagLine }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 text-md-right>
        <div v-if="isLoggedIn" class="d-flex justify-end">
          <NotificationButton />
          <v-menu offset-y class="flex-column" left>
            <template #activator="{ on, attrs }">
                  <v-list-item-avatar class="rounded ma-0" size="48">
                    <v-img
                      v-bind="attrs"
                      class="elevation-6"
                      alt="ProfileImg"
                      :src="profileImage"
                      v-on="on"
                    />
                  </v-list-item-avatar>
            </template>
            <v-list>
              <v-list-item v-if="isAdmin" to="/admin/dashboard">
                <v-list-item-title>Admin Dashboard</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!isTalent && !isAdmin" to="/upcoming-sessions">
                <v-list-item-title>Upcoming Sessions</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!isAdmin" to="/account/settings">
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isTalent && !isAdmin" to="/talent/dashboard">
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item v-if="isTalent && !isAdmin" to="/talent/sessions">
                <v-list-item-title>Sessions</v-list-item-title>
              </v-list-item> -->
              <!-- <v-list-item
                v-if="isTalent && !isAdmin"
                to="/talent/availability"
              >
                <v-list-item-title>Availability</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isTalent && !isAdmin"
                to="/talent/appointments"
              >
                <v-list-item-title>Appointments</v-list-item-title>
              </v-list-item> -->
              <v-list-item @click="logout()">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn
            text
            class="mr-3 font-weight-bold"
            :class="{ 'px-1': !isMdAndAbove }"
            :style="!isMdAndAbove ? 'justify-content: start;' : null"
            @click="loginModal()"
          >
            {{ $t('login_form_title') }}
          </v-btn>
          <v-btn width="140" color="secondary" class="white--text" to="/signup">
            <!-- Talent Signup -->
            {{ $t('join_today') }}
          </v-btn>
        </div>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isMobile } from 'mobile-device-detect'

// component
import NotificationButton from '@/components/generic/NotificationButton.vue'

// graphql
import { SEARCH_TALENT } from '@/graphql/talent'

export default {
  components: {
    NotificationButton,
  },
  props: {
    fade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile,
      appBarColor: 'background: transparent !important',
      searchedTalents: [],
      search: null,
      talent: null,
      isHidden: false,
      menu: false,
      profileIcon:
        'https://meetwithanyone-assets.s3.amazonaws.com/defaultProfileImg.svg',
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isTalent',
      'profileImage',
      'isAdmin',
      'isRegisterCompleted',
    ]),
    isMdAndAbove() {
      return ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
    image() {
      return this.profileImage ?? this.profileIcon
    },
  },
  watch: {
    search(val) {
      if (!val) {
        this.clearSearch()
        return
      }
      this.searchTalent(val)
    },
  },
  created() {
    if (this.isTalent && !this.isRegisterCompleted) this.logout()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    /**
     * HandleScroll
     */
    handleScroll() {
      if (window.scrollY > 10) {
        // this.search = null
        // this.talent = null
        this.appBarColor = 'background: #F1F3F6 !important'
      } else if (window.scrollY == 0) {
        this.appBarColor = 'background: transparent !important'
      }
    },

    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },

    /**
     * Open SignUp Modal
     */
    signUpModal() {
      this.menu = false
      this.$router.push(`/signup`)
      // this.$store.commit('signupModal', true)
    },

    /**
     * Open Login Modal
     */
    loginModal() {
      this.$store.commit('loginModal', true)
    },

    /**
     * Close SignUp Modal
     */
    closeSignupModal() {
      this.$store.commit('signupModal', false)
    },

    /**
     * Close Login Modal
     */
    closeLoginModal() {
      this.$store.commit('loginModal', false)
    },

    // Search Talent
    async searchTalent(keyword) {
      if (keyword) {
        const {
          data: { searchTalent },
        } = await this.$apollo.query({
          query: SEARCH_TALENT,
          variables: {
            searchInput: {
              name: keyword,
              pagination: {
                first: 0,
                skip: 0,
              },
            },
          },
        })

        if (searchTalent.talentList) {
          // Prepare object for searchbox
          this.searchedTalents = searchTalent.talentList.map(t => {
            return {
              name: `${t.user.firstName} ${t.user.lastName}`,
              slug: t.user.username,
              id: t.user.id,
              profilePic: t.user.profileImg ? t.user.profileImg : null,
              tagLine: t.shortIntro,
            }
          })
        } else {
          this.searchedTalents = []
        }
      }
    },

    // Redirect to talent profile page
    toProfile(slug) {
      this.$nextTick(() => {
        this.search = null
        this.talent = null
      })
      this.searchedTalents = []
      if (slug) this.$router.push(`/talent/profile/${slug}`)
    },

    // Clear Search
    clearSearch() {
      this.search = null
      this.talent = null
      this.searchedTalents = []
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-bar {
  position: -webkit-sticky;
  position: fixed;
  top: 0px;
  z-index: 99;
  width: 100%;
  padding: 30px 0;
}
.relative-bar {
  width: 100%;
  padding: 30px 0;
}
.mobile-view {
  display: none;
}
@media screen and (max-width: 991px) {
  .mobile-view {
    display: block;
    padding: 10px;
  }
  .desktop-view {
    display: none;
  }
}
</style>
<style scoped>
.mobile-menu >>> .theme--light.v-list {
  width: 48px;
}
</style>
