<template>
  <v-fade-transition>
    <v-layout
      v-if="$loading"
      key="loading-overlay-key"
      justify-center
    >
      <v-progress-circular
        indeterminate
        size="200"
        color="red"
      />
    </v-layout>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'LoadingOverlay',
}
</script>

<style lang="scss" scoped>
    .overlay{
        align-items: center;
        background-color: rgba(84,86,90,.5);
        height: 100vh;
        position: fixed;
        width: 100vw;
        left: 0;
        top: 0;
        z-index: 100
    }
</style>
