// export const API_ENDPOINT = 'https://api.meetwithdev.com'
// export const API_ENDPOINT = 'http://localhost:8000'
export const API_ENDPOINT = 'https://api.meetwithanyone.com' // PROD

export const REDIRECT_URL = 'https://meetwithanyone.com/signup' // PROD
// export const REDIRECT_URL = 'https://meetwithdev.com/signup' // DEV
// export const REDIRECT_URL = 'http://localhost:8080/signup' // DEV

// STRIPE
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_GPntqPk60dM8dktAmpjhzmec' // PROD
export const STRIPE_LIVE_CLIENT_ID = 'ca_EuSmIpGv1YZhegTBfBzFN2gMUBlOyHmG' // PROD
export const TEST_STRIPE_PUBLISHABLE_KEY = 'pk_test_ld34mGMueBKYFY4rnlJzMyjY' // DEV
export const TEST_STRIPE_LIVE_CLIENT_ID = 'ca_EuSmABa2Pdv4XPXVgTtYHsUEXgl3NPVl' // DEV

// GOOGLE CLIENT ID
export const GOOGLE_CLIENT_ID =
  '706398625321-gog810a76kd8t66ecdhsv7tuqivroi96.apps.googleusercontent.com' //PROD
export const TEST_GOOGLE_CLIENT_ID =
      '951957364416-2blktm2vs52ucrtfttapt486inr4d2a3.apps.googleusercontent.com' //DEV
