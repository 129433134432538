import Vue from 'vue'
import { createProvider } from '@/utils/vue-apollo'
// graphql
import { GET_NOTIFICATIONS } from '@/graphql/account'
const apollo = createProvider().defaultClient

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Format date based in user timezone
 * @param {*} ms
 * @returns
 */
export const formatDateBasedOnTZ = (date, format) =>
  this.$moment(this.$moment(`${date} UTC`).toISOString())
    .tz(this.$moment.tz.guess(Boolean))
    .format(format)

/**
 * Compare talent name
 * @param {a} FirstName
 * @param {b} SecondName
 */
export const compare = (a, b) => {
  if (a.displayName > b.displayName) {
    return 1
  } else if (a.displayName < b.displayName) {
    return -1
  }
  return 0
}

const getTimeAgoDate = date => {
  return Vue.prototype
    .$moment(Vue.prototype.$moment(date).toISOString())
    .tz(Vue.prototype.$moment.tz.guess(Boolean))
    .fromNow()
}

export const getPriceWithRound = (origin) => {
  return (origin+origin*0.029+0.3).toFixed(2);
}

const computedDateFormatted = (date, dateFormat) => {
  return Vue.prototype.$moment(date).format(dateFormat)
}

export const getNotificationslist = async params => {
  try {
    const { email, token, pageNumber, skip, notiForheader } = params
    const {
      data: { getNotifications },
    } = await apollo.query({
      query: GET_NOTIFICATIONS,
      fetchPolicy: 'no-cache',
      variables: {
        tokenInput: {
          email: email,
          accesstoken: token,
        },
        pagination: {
          first: pageNumber,
          skip: skip,
        },
        tzinfo: Vue.prototype.$defaultTZ,
      },
    })

    const { getters, commit } = Vue.prototype.$myStore
    if (getNotifications.success) {
      const notifications = []
      getNotifications.notifications.forEach(notification => {
        if (getters.isTalent) {
          if (notification.action === 'session_booked') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `Congratulations! Your session <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> is booked by <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.user.firstName
              }</span> for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          } else if (notification.action === 'session_updated') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `Session <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> with <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.user.firstName
              }</span> is now scheduled for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          } else if (notification.action === 'session_starting') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `Your appointment with <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.user.firstName
              }</span> will start at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          } else if (notification.action === 'session_extended') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `Congratulations! Session <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> is extented by <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.user.firstName
              }</span> to at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.endTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          }
        } else {
          if (notification.action === 'session_updated') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `your session  <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> with <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.host.firstName
              }</span> is now scheduled for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          } else if (notification.action === 'session_starting') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `<span class="text-subtitle-1 pr-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> session with <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.host.firstName
              }</span> will start at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          } else if (notification.action === 'session_booked') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `Congratulations! Your session <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> is booked with <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.host.firstName
              }</span> for <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.startTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          } else if (notification.action === 'session_extended') {
            notifications.push({
              createdOn: notification.createdOn,
              id: notification.id,
              read: notification.read,
              // eslint-disable-next-line
              message: `Congratulations! Your session <span class="text-subtitle-1 pl-1 black--text notifications">${
                notification.bookedSession.sessionType.title
              }</span> is extented to at <span class="text-subtitle-1 pl-1 black--text notifications">${computedDateFormatted(
                `${notification.bookedSession.date} ${notification.bookedSession.endTime}`,
                'dddd MMMM DD, YYYY hh:mm a'
              )}</span>`,
            })
          }
        }
      })

      if (getNotifications.unreadNotifications == 0) {
        await commit('markNotificationAsRead', true)
      } else {
        await commit('markNotificationAsRead', false)
      }

      if (notiForheader) {
        await commit('setUserNotifications', notifications)
      }

      return {
        notifications,
        totalNotifications: getNotifications.totalNotifications,
      }
    }
  } catch (error) {
    console.log(error)
  }
}
