<template>
  <v-row
    justify="center"
    align="center"
  >
    <!-- eslint-disable-next-line -->
    <v-dialog v-model="dialog"
              persistent
              :width="width"
    >
      <v-card color="white">
        <v-form
          ref="forgotForm"
          lazy-validation
        >
          <v-btn
            icon
            light
            absolute
            right
            class="mr-n4"
            color="black"
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-container
            fluid
            class="pa-5"
          >
            <v-card-text class="text--primary text-center py-2">
              <v-row
                class="mt-5"
                no-gutters
              >
                <v-flex
                  xs12
                >
                  <AlertBox
                    v-if="error"
                    :message="error"
                  />
                </v-flex>
                <v-flex
                  xs12
                >
                  <AlertBox
                    v-if="success"
                    :message="success"
                    :is-error="false"
                  />
                </v-flex>
                <v-flex
                  xs12
                  mb-4
                >
                  <v-card-text class="text-none text-h3 text-left pl-0 pb-0">
                    {{ $t('forgot_pwd') }}
                  </v-card-text>
                  <v-card-text class="text-left pl-0 pt-2">
                    <p class="light-text text-h6 text-warp">
                      {{ $t('forgot_text') }}
                    </p> 
                  </v-card-text>
                  <v-text-field
                    v-model.trim="email"
                    :rules="rules.email"
                    :placeholder="$t('email_address')"
                    outlined
                    hide-details="auto"
                  />
                </v-flex>
                <v-flex
                  xs12
                  mb-4
                >
                  <v-btn
                    block
                    color="secondary"
                    dark
                    class="white--text"
                    :loading="loading"
                    @click="forgot()"
                  >
                    {{ $t('reset_pwd') }}
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  mb-4
                >
                  <v-btn
                    block
                    text
                    dark
                    class="black--text"
                    @click="back()"
                  >
                    {{ $t('back') }}
                  </v-btn>
                </v-flex>
              </v-row>
            </v-card-text>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// graphql
import { RESET_PASSWORD, RESEND_PASSWORD_RESET } from "@/graphql/authentication.js";

// utils
import { rules } from '@/utils/validation.js'
import i18n  from '@/utils/i18n.js'

export default {
  name: 'ForgotPassword',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 430,
    },
  },
  data() {
    return {
      loading:false,
      error: null,
      success: null,
      email: null,
      rules,
      codeId: null,
    }
  },
  methods: {
    /**
     * Close ForgotPasswordModal
     */
    close(){
      this.success = false
      this.error = false
      this.$store.commit('forgotPasswordModal', false)
    },
    /**
     * Reset password by email
     */
    async forgot() {
      this.error = null
      if (this.$refs.forgotForm.validate()) {
        this.loading = true
        try {
          const { data: { requestResetPassword } } = await this.$apollo.mutate({
            mutation: RESET_PASSWORD,
            variables: 
              { 
                email: this.email,
              },
          });
          if(requestResetPassword.verificationId) {
            this.codeId = requestResetPassword.verificationId;
            this.resendPassword();
          } else {
            if (requestResetPassword.success) {
              this.loading = false
              this.$refs.forgotForm.reset()
              this.success = i18n.t('success_reset_pwd_msg')
            } else {
              this.loading = false
              this.error = i18n.t(requestResetPassword.error)
            }
          }        
        } catch (e) {
          this.loading = false
          this.error = e
        }
      }
    },
    async resendPassword() {
      this.error = null
      this.success = null
      this.loading = true
      try {
        const { data: { resendPasswordReset } } = await this.$apollo.mutate({
          mutation: RESEND_PASSWORD_RESET,
          variables: 
            { 
              codeId: this.codeId,
            },
        });
        
        if (resendPasswordReset.success) {
          this.loading = false
          this.$refs.forgotForm.reset()
          this.success = i18n.t('success_reset_pwd_msg')
        } else {
          this.loading = false
          this.error = i18n.t(resendPasswordReset.error)
        }
      } catch (e) {
        this.loading = false
        this.error = e
      }
    },
    /**
     * Go Back to LoginModal
     */
    back(){
      this.close()
      this.$store.commit('loginModal', true)
    },
  },
}
</script>
<style>
.v-btn:before {
  background-color: unset; 
}
</style>