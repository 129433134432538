<template>
  <v-alert
    v-if="show"
    dark
    class="black--text text-left font-weight-medium text-wrap error-card align-center text-subtitle-2"
    :class="classes"
    :color="isError ? 'light_error': 'light-green lighten-3'"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'AlertBox',
  props: {
    isError: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "light_error",
    },
    message: {
      type: String,
      default: null,
    },
    timeout:{
      type: Number,
      default: 5000,
    },
    classes: {
      type: String,
      default: null,
    },
  },
  data(){
    return {
      show:false,
      timeObj:null,
    }
  },
  watch:{
    message: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val){
        clearTimeout(this.timeObj)
        this.show = (val != null) ? true : false
        this.timeObj = setTimeout(()=>{
          this.show = false;
          this.$emit('update:message', null)
        },this.timeout)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .error-card {
    letter-spacing: -0.25px !important;
  }
</style>
