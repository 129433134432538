import gql from 'graphql-tag'

export const SEARCH_TALENT = gql`
  query DisplayTalentOutput($searchInput: TalentSearchInput!) {
    searchTalent(searchInput: $searchInput) {
      success
      talentList {
        id
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
          zipcode
          city
        }
        shortIntro
        longIntro
        verified
        featured
        followersCount
        introVideo
        areas {
          id
          name
          order
        }
        price
      }
      first
      skip
      totalRecords
      error
    }
  }
`

export const FEATURED_TALENT = gql`
  query DisplayTalentOutput($pagination: PaginationInput) {
    getFeaturedHosts(pagination: $pagination) {
      success
      talentList {
        id
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
          zipcode
          city
          didFinishRegistration
        }
        shortIntro
        longIntro
        verified
        featured
        followersCount
        introVideo
        areas {
          id
          name
          order
        }
      }
      first
      skip
      totalRecords
      error
    }
  }
`

export const GET_ALL_TALENT = gql`
  query DisplayTalentOutput($pagination: PaginationInput) {
    getTalentList(pagination: $pagination) {
      success
      talentList {
        id
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
          zipcode
          city
          didFinishRegistration
        }
        shortIntro
        longIntro
        verified
        featured
        followersCount
        introVideo
        areas {
          id
          name
          order
          level
        }
        price
      }
      first
      skip
      totalRecords
      error
    }
  }
`

export const SET_AVAILABILITY = gql`
  mutation SetAvailabilityMutation($availableData: AvailabilityInput!) {
    setTalentAvailability(availableData: $availableData) {
      success
      error
    }
  }
`

export const FETCH_AVAILABILITY = gql`
  query AvailabilityOutput($authInput: FetchAvailabilityInput!) {
    fetchAvailability(authInput: $authInput) {
      success
      error
      availability {
        date
        startTime
        endTime
        id
        allDays
      }
    }
  }
`
export const PROFILE = gql`
  query BookingPageInfoType($username: String!) {
    getBookingPageInfo(username: $username) {
      success
      profile {
        id
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
          verifiedEmail
          country {
            id
            code
            name
            enabled
          }
          zipcode
          city
        }
        shortIntro
        longIntro
        verified
        featured
        followersCount
        introVideo
        areas {
          id
          name
          order
          level
        }
      }
      sessions {
        id
        title
        length
        charge
      }
      gallery {
        id
        image
      }
      error
    }
  }
`

export const GET_TALENT_AVAILABILITY = gql`
  query AppointmentCheckListOutput($appointment: CheckAppointmentsInput!) {
    fetchAppointments(appointment: $appointment) {
      success
      appointmentList {
        startTime
        endTime
        booked
        date
        ispast
        host {
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
        }
      }
      error
    }
  }
`

export const GET_TALENT_DASHBOARD = gql`
  query DisplayDashboardOutput($talentInput: TokenInput!, $tzinfo: String) {
    getTalentDashboard(talentInput: $talentInput, tzinfo: $tzinfo) {
      success
      error
      sessionList {
        id
        guest {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
        sessionType {
          id
          title
          length
          charge
        }
        date
        startTime
        endTime
        completed
        createdOn
      }
      totalBooking
      totalCompleteSession
      sessionToday {
        id
        guest {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
        sessionType {
          id
          title
          length
          charge
        }
        date
        startTime
        endTime
        completed
        createdOn
      }
      totalToday
      recentPayments {
        payoutid
        createdOn
        serviceCharge
        charge
        netPayout
        status
        paidBy {
          id
          username
          firstName
          lastName
          email
          profileImg
        }
      }
      totalEarning
    }
  }
`
