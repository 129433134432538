import { ApolloClient } from 'apollo-client'
// import { HttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import Vue from 'vue'
import { API_ENDPOINT as baseUrl } from '../consts'

import VueApollo from 'vue-apollo'

const getAuth = tokenName => {
  const store = JSON.parse(localStorage.getItem('store'))
  const token = store ? store[tokenName] : undefined
  return token ? `Bearer ${token}` : undefined
}

// User For File Upload And Noraml Call
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: `${baseUrl}/graphql`,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(
      ({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        ) // eslint-disable-line
    )
  }
  if (networkError) console.log(`[Network error]: ${networkError}`) // eslint-disable-line
})

export function createProvider(options = {}) {
  const authLink = setContext((_, { headers = {} }) => {
    const context = {
      headers: {
        ...headers,
      },
    }

    const authToken = getAuth('jwt')
    if (authToken && authToken.trim().length > 0) {
      context.headers.Authorization = authToken
    } else {
      delete context.headers.Authorization
    }
    return context
  })

  // Concat all the http link parts
  const link = authLink.concat(httpLink, errorLink)

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      resultCaching: false,
      freezeResults: false,
      addTypename: false,
    }),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  })

  Vue.use(VueApollo)

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        result: (a, b) => {
          console.log(a, b)
        },
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.error('API error:')
      console.error(error.message)
    },
  })
  return apolloProvider
}
