<template>
  <v-row justify="center" align="center">
    <!-- eslint-disable-next-line -->
    <v-dialog v-model="dialog" persistent :width="width">
      <v-card color="white">
        <v-btn
          icon
          light
          absolute
          right
          class="mr-n4"
          color="black"
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-form ref="loginForm" lazy-validation>
          <v-container fluid class="pa-5">
            <v-card-text class="text--primary text-center py-2">
              <v-row no-gutters class="mt-5">
                <AlertBox :message="error" />
                <!-- <v-flex xs12 mb-4 mt-2>
                  <v-card color="yellow" height="48" width="auto">
                    <v-card-text class="font-weight-medium">
                      {{ $t('book_session_continue_google') }}
                    </v-card-text>
                  </v-card>
                </v-flex> -->

                <v-flex xs12 mt-4 mb-3>
                  <v-btn
                    large
                    min-height="64"
                    block
                    color="blue darken-4"
                    dark
                    :loading="googleLoading"
                    class="white--text text-subtitle-2 no-shadow"
                    @click="LoginWithGoogle()"
                  >
                    <v-icon left class="mr-5">
                      mdi-google
                    </v-icon>
                    {{ $t('continue_google') }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 mt-3 mb-6>
                  <v-row no-gutters align="center">
                    <v-flex xs5>
                      <v-divider color="black" />
                    </v-flex>
                    <v-flex xs2>
                      {{ $t('or') }}
                    </v-flex>
                    <v-flex xs5>
                      <v-divider color="black" />
                    </v-flex>
                  </v-row>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model.trim="credentials.email"
                    data-cy="Email Login Page"
                    :rules="rules.email"
                    :placeholder="$t('email_address')"
                    outlined
                    required
                  />
                </v-flex>
                <v-flex xs12 mt-n1>
                  <v-text-field
                    v-model="credentials.password"
                    data-cy="Password Login Page"
                    :rules="rules.required"
                    :placeholder="$t('password')"
                    outlined
                    required
                    type="password"
                    @keyup.enter.native="login()"
                  />
                </v-flex>
                <v-flex xs12 mb-4 mt-n4 text-right>
                  <v-card-text
                    class="text--primary text-none text-subtitle-2 ml-5 font-weight-bold"
                  >
                    <a class="black--text" @click="forgotPassword()">{{
                      $t('forgot_pwd')
                    }}</a>
                  </v-card-text>
                </v-flex>
                <v-flex xs12 mb-4>
                  <v-btn
                    data-cy="Login Page Login"
                    large
                    min-height="64"
                    block
                    color="secondary"
                    dark
                    class="white--text no-shadow"
                    :loading="loading"
                    @click="login()"
                  >
                    {{ $t('log_in') }}
                  </v-btn>
                </v-flex>
              </v-row>
              <v-row no-gutters align="center">
                <v-flex xs12 mt-4>
                  <v-divider color="black" />
                </v-flex>
              </v-row>
              <v-flex xs12 mb-n5 mt-6>
                <span class="black--text text-subtitle-1">
                  <p>
                    {{ $t('account_dnt_exist')
                    }}<a
                      class="black--text pl-1 text-decoration-underline"
                      @click="signUpModal()"
                      >{{ $t('create') }}</a
                    >
                  </p>
                </span>
              </v-flex>
            </v-card-text>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

// utils
import { rules } from '@/utils/validation.js'
import { getNotificationslist } from '@/utils/helpers.js'

export default {
  name: 'Login',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 430,
    },
  },
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      loading: false,
      googleLoading: false,
      error: null,
      rules,
    }
  },
  computed: {
    ...mapGetters(['isTalent', 'jwt', 'user']),
    getTimeAgoDate() {
      return date =>
        this.$moment(this.$moment(date).toISOString())
          .tz(this.$moment.tz.guess(Boolean))
          .fromNow()
    },
    computedDateFormatted() {
      return (date, dateFormat) => this.$moment(date).format(dateFormat)
    },
  },
  methods: {
    /**
     * Login
     */
    async login() {
      this.error = null
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        try {
          const {
            data: { userLogin },
          } = await this.$store.dispatch('login', {
            ...this.credentials,
          })

          // Set User Info
          await this.setUser(userLogin)
        } catch (e) {
          this.loading = false
          // eslint-disable-next-line no-console
          console.log(e)
          this.error = e.message
        }
      }
    },

    /**
     * SignupModal/LoginModal hide show
     */
    signUpModal() {
      this.$store.commit('loginModal', false)
      this.$router.push('/signup')
    },

    /**
     * Close LoginModal
     */
    close() {
      this.$store.commit('loginModal', false)
    },

    /**
     * Open ForgotPasswordModal
     */
    forgotPassword() {
      this.close()
      this.$store.commit('forgotPasswordModal', true)
    },

    /**
     * Login with Google
     */
    async LoginWithGoogle() {
      this.googleLoading = true
      try {
        const googleUser = await this.$gAuth.signIn()
        if (!googleUser) {
          this.googleLoading = false
          return null
        }
        const { id_token } = googleUser.getAuthResponse()
        const basicProfile = googleUser.getBasicProfile()

        const {
          data: { googleLogin },
        } = await this.$store.dispatch('loginWithGoogle', {
          tokenId: id_token,
          firstName: basicProfile.BT,
          lastName: basicProfile.xR,
          email: basicProfile.At,
        })

        // Set User Info
        await this.setUser(googleLogin)
      } catch (error) {
        //on fail do something
        console.error(error)
        this.googleLoading = false
        return null
      }
    },

    /**
     * Set User Info After Successful Login
     */
    async setUser(userInfo) {
      if (userInfo.success) {
        this.googleLoading = false
        this.loading = false
        userInfo.user.istalent = userInfo.istalent
        userInfo.user.isstaff = userInfo.isstaff
        await this.$store.commit('setUser', userInfo.user)
        await this.$store.commit('setJwt', userInfo.accesstoken)
        // bind pusher event
        this.$setupPusherEvent()
        // await this.getNotifications()
        await getNotificationslist({
          email: userInfo.user.email,
          token: userInfo.accesstoken,
          pageNumber: 4,
          skip: 0,
          notiForheader: true,
        })
        this.$refs.loginForm.reset()
        this.close()
        let path = null
        if (this.$route.query.redirect) {
          path = this.$route.query.redirect
        } else if (userInfo.istalent) {
          path = '/talent/sessions'
        } else if (userInfo.isstaff) {
          path = '/admin/dashboard'
        } else {
          path = '/account/settings'
        }
        this.$router.push(path)
      } else {
        this.loading = false
        this.googleLoading = false
        this.error = userInfo.error
      }
    },
  },
}
</script>
