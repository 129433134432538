<template>
  <v-card
    flat
    tile
    color="light_grey"
    class="pa-2 pt-5"
    :height="isMdAndAbove ? '400' : null"
  >
    <v-container>
      <v-row
        no-gutters
        justify="start"
        :class="isMdAndAbove ? 'section' : 'px-3'"
      >
        <v-flex xs12 md3>
          <router-link to="/" class="black--text text-decoration-none">
            <div class="logo-text ml-5 ml-md-0 mb-sm-5">
              <img
                src="@/assets/images/logo/with-yellow.png"
                height="50%"
                width="30%"
              />
            </div>
          </router-link>
          <v-card-text v-if="isMdAndAbove" class="mt-3 px-md-0">
            <div class="text-subtitle-2">
              {{ $t('copyright') }}{{ copyrightYear }} {{ $t('with_inc') }}
            </div>
            <div class="text-subtitle-2 mt-2">
              {{ $t('all_right_reserve') }}
            </div>
          </v-card-text>
          <v-card-text class="pt-1 px-md-0 py-4 ">
            <!-- <a href="https://facebook.com/raveVIPofficial">
              <img
                target="_blank"
                width="25"
                height="25"
                src="@/assets/images/icons/social-media/facebook.svg"
                alt="facebook"
              >
            </a> -->
            <a href="https://www.tiktok.com/@meetwithanyone" class="px-8">
              <img
                target="_blank"
                width="25"
                height="25"
                src="@/assets/images/icons/social-media/tiktok.svg"
                alt="tiktok"
              />
            </a>
            <a href="https://www.instagram.com/meetwithanyone/">
              <img
                target="_blank"
                width="25"
                height="25"
                src="@/assets/images/icons/social-media/instagram.svg"
                alt="instagram"
              />
            </a>
          </v-card-text>
        </v-flex>
        <v-flex xs12 md3>
          <v-list-item class="text-subtitle-1 font-weight-bold mb-n3 mt-8">
            {{ $t('company') }}
          </v-list-item>
          <v-list-item
            v-for="(support_link, i) in support_links"
            :key="i"
            :class="support_link.myN4"
          >
            <router-link
              :to="support_link.url"
              class="text-left text-subtitle-2 text-decoration-none black--text"
            >
              {{ support_link.text }}
            </router-link>
          </v-list-item>
        </v-flex>
        <v-flex xs12 md3>
          <v-list-item class="text-subtitle-1 font-weight-bold mb-n3 mt-8">
            {{ $t('contact') }}
          </v-list-item>
          <v-list-item class="my-n4">
            <a
              href="mailto:hello@meetwithanyone.com"
              class="text-left text-subtitle-2 text-decoration-none black--text"
              target="_blank"
            >
              {{ $t('email') }}
            </a>
          </v-list-item>
        </v-flex>
        <v-flex xs12 md3>
          <v-card-text class="mt-8">
            <p class="mt-0 text-subtitle-1 font-weight-bold">
              {{ $t('how_can_i_use_with') }}
            </p>
            <v-btn
              color="primary"
              class="white--text"
              depressed
              min-width="200px"
              to="/signup"
            >
              {{ $t('join_today') }}
            </v-btn>
            <!-- <v-btn
              color="white"
              class="black--text mt-3 font-weight-bold"
              depressed
              min-width="200px"
            >
              {{ $t('become_affiliate') }}
            </v-btn> -->
          </v-card-text>
        </v-flex>
        <v-flex v-if="!isMdAndAbove" xs12 md4 px-md-16>
          <v-card-text class="px-md-0">
            <div class="text-subtitle-2">
              {{ $t('copyright') }}{{ copyrightYear }}{{ $t('with_inc') }}
            </div>
            <div class="text-subtitle-2 mt-2">
              {{ $t('all_right_reserve') }}
            </div>
          </v-card-text>
        </v-flex>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      copyrightYear: new Date().getFullYear(),
      support_links: [
        {
          text: 'Terms of Service',
          url: '/terms',
        },
        {
          text: 'Privacy Policy',
          url: '/privacy',
          myN4: 'my-n4',
        },
        {
          text: 'FAQ',
          url: '/faq',
        },
        // {
        //   text: 'About Us',
        //   url: '/about',
        //   myN4: 'my-n4',
        // },
        // {
        //     text: 'Contact',
        //     url: '#'
        // },
        // {
        //     text: 'Become an affiliate partner',
        //     url: '#',
        //     myN4: 'my-n4'
        // },

        // {
        //     text: 'Account Set Up',
        //     url: '/account-setup',
        //     myN4: 'my-n4'
        // },
      ],
      contact_links: [
        {
          text: 'Email',
          url: '#',
          myN4: 'my-n4',
        },
      ],
    }
  },
  computed: {
    isMdAndAbove() {
      return ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
  },
}
</script>
