import i18n from './i18n'


export const rules = {
  required: [ v => !!v || i18n.t('field_err_txt') ],
  required_short_text: [ v => !!v || i18n.t('required_short_text') ],
  cardNumber:[
    v => !!v || i18n.t('field_err_txt'),
    v => (!!v && /^([0-9]{4})\s([0-9]{4})\s([0-9]{4})\s([0-9]{4})*$/.test(v)) || i18n.t('card_number_txt'),
  ],
  cardDate:[
    v => !!v || i18n.t('field_err_txt'),
    v => (!!v && /^[0-9]{2}[\/]*[0-9]{2}$/.test(v)) || i18n.t('card_date_txt'),
  ],
  cardCvv:[
    v => !!v || i18n.t('field_err_txt'),
    v => (!!v && /^[0-9]{3}$/.test(v)) || i18n.t('card_cvv_txt'),
  ],
  password: [
    v => !!v || i18n.t('field_err_txt'),
    v => (v || '').indexOf(' ') < 0 || i18n.t('blank_txt'),
    v => (!!v && v.length >= 8) || i18n.t('pwd_txt'),
    v => (!!v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(v)) || i18n.t('pwd_val_txt'),
  ],
  birthday: [
    v => !!v || i18n.t('field_err_txt'),
    v => (v && this.$moment(v).isValid()) || i18n.t('birthday_txt'),
  ],
  zipCode: [
    v => !!v || i18n.t('field_err_txt'),
    v => (v && /^(?:[A-Z0-9]*)?$/.test(v) || i18n.t('zip_code_txt')),
    v => (v && v.length >=5 && v.length <= 7) || i18n.t('zip_code_txt'),
  ],
  phone: [
    v => !!v || i18n.t('field_err_txt'),
    v =>
      (v && /^[0-9]*$/.test(v) && v.length <= 11) ||
            i18n.t('phone_txt'),
  ],
  duration: [
    v =>
      (v >= 5) ||
            i18n.t('duration_txt'),
  ],
  tagLine: [
    v => !!v || i18n.t('field_err_txt'),
    v => (!!v && v.length <= 60) || i18n.t('tag_line_txt'),
  ],
  email: [
    v => !!v || i18n.t('email_void_txt'),
    v => /^[a-zA-Z0-9._+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || i18n.t('email_err_txt'),
  ],
  name:[
    v => !!v || i18n.t('field_err_txt'),
    v => (!!v && !!v.trim()) || i18n.t('field_err_txt'),
    v => /^[A-Za-z \-']+$/.test(v) || i18n.t('valid_name_err_txt'),
  ],
  social_media: [
    v => !!v || i18n.t('selection_txt'),
  ],
  description: [
    v => !!v || i18n.t('description_message'),
    v => (v && v.trim().length > 0) || i18n.t('blank_space_message'),
  ],
  welcomeMessage: [
    v => !!v || i18n.t('welcome_message'),
    v => (v && v.trim().length > 0) || i18n.t('blank_space_message'),
  ],
  session_name: [
    v => !!v || i18n.t('session_name'),
    v => (!!v && !!v.trim()) || i18n.t('session_name_invalid'),
    v => /^[ A-Za-z0-9_@./#!$%^&*()&+,'-]*$/.test(v) || i18n.t('session_name_invalid'),
  ],
  session_length: [
    v => !!v || i18n.t('session_length'),
  ],
  session_charge: [
    v => !!v || i18n.t('session_charge'),
    v => /^[0-9.]*$/.test(v) || i18n.t('session_charge_num'),
  ],
  confirm_password: [
    v => !!v || i18n.t('confirm_pwd'),
  ],
  date: [
    v => !!v || i18n.t('select_date'),
  ],
  session_time: [
    v => !!v || i18n.t('select_session_tm'),
  ],
  city_name:[
    v => !!v || i18n.t('field_err_txt'),
    v => (!!v && !!v.trim()) || i18n.t('valid_city_name_err_txt'),
    v => /^[A-Za-z ,-]+$/.test(v) || i18n.t('valid_city_name_err_txt'),
  ],
  username:[
    v => !!v || i18n.t('field_err_txt'),
    v => /^[A-Za-z0-9_./-]+$/.test(v) || i18n.t('valid_user_name_err_txt'),
  ],
  city_name_homepage:[
    v => /^[A-Za-z ,-]+$/.test(v) || i18n.t('valid_city_name_err_txt'),
  ],
  zipCode_homepage: [
    v => (v && /^(?:[A-Z0-9]*)?$/.test(v) || i18n.t('zip_code_txt')),
    v => (v && v.length >=5 && v.length <= 7) || i18n.t('zip_code_txt'),
  ],
  time: [
    v => !!v || i18n.t('required_short_text'),
    v => (/^(0?[1-9]|1[012]):[0-5][0-9] ([AP]M)$/.test(v) || i18n.t('valid_date_txt')),
  ],
}
