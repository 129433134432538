export default {
  plugin: (init, localStorageName) => store => {
    store.commit(init)
    store.subscribe((mutation, state) => {
      localStorage.setItem(localStorageName, JSON.stringify(state))
    })
  },
  mutation: (localStorageName, setup) => state => {
    const dehydratedState = localStorage.getItem(localStorageName)
    if (dehydratedState) {
      const hydratedState = JSON.parse(dehydratedState)
      if (Array.isArray(setup)) {
        setup.forEach(s => s(hydratedState))
      }
      if (typeof setup === 'function') {
        setup(hydratedState)
      }
      state = Object.assign(state, hydratedState) // eslint-disable-line
    }
  },
}
